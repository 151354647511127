<template>
  <div>
    <div class="ps-block--vendor-dashboard">
      <div
          class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
      >
        <h3>Runners</h3>
        <div class="justify-content-between">
          <router-link
              :to="{ name: 'admin_create_runner' }"
              v-if="auth.isAuthenticated"
              class="ps-btn btn-sm"
          ><i class="icon-plus"></i> Create Runner</router-link
          >
          &nbsp;&nbsp;
          <router-link
              :to="{ name: 'admin_runners_categories' }"
              v-if="auth.isAuthenticated"
              class="ps-btn btn-sm"
          ><i class="icon-line-spacing"></i> Categories </router-link
          >
          &nbsp;&nbsp;
          <router-link
              :to="{ name: 'admin_runners_requests' }"
              v-if="auth.isAuthenticated"
              class="ps-btn btn-sm"
          ><i class="icon-cart-exchange"></i> Requests </router-link
          >
        </div>
      </div>
      <div class="ps-block__content">

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor table-bordered">
            <thead>
            <tr>
              <th>Date</th>
              <th>Runner Name</th>
              <th>Category</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="runner in runners" :key="runner.id">
              <td>{{ runner.created_at|formatDate }}</td>
              <td><span class="text-muted">{{runner.name }}</span></td>
              <td>{{ runner.category.name }}</td>
              <td>
                <span v-if="runner.status === 0 " class="text-warning">Pending </span>
                <span v-if="runner.status === 1" class="text-success">Active </span>
              </td>
              <td>
                <router-link :to="{name: 'admin_edit_runner', params: {id: runner.id}}"><i class="icon-pencil"></i></router-link>
                &nbsp;&nbsp;
                <router-link :to="{name: 'admin_runner_detail', params: {id: runner.id}}"><i class="icon-eye"></i></router-link>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr v-if="(totalRunners === 0) && !isLoading">
              <td colspan="6" class="text-center m-4 text-danger p-4">No runners available</td>
            </tr>
            <tr v-else-if="isLoading">
              <td colspan="6" class="text-center m-4 text-danger">Loading runners...</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "ListRunners",
  data() {
    return {
      runners:[],
      totalRunners: 0,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    this.getRunners();
  },
  methods: {
    getRunners() {
      this.isLoading = true
      axios.get('runners').then((response) => {
        this.isLoading = false
        this.runners = response.data.data
        this.totalRunners = response.data.total
      }).catch((error) => {
        this.isLoading = false
      });
    },
  },
}
</script>

<style scoped>

</style>